import React, { useContext, useEffect, useState } from "react";
import NuwaAvatar from "../Components/NuwaAvatar";
import SearchForm from "../Components/SearchForm";
import SystemSecurityUpdateWarningIcon from '@mui/icons-material/SystemSecurityUpdateWarning';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useNavigate } from "react-router-dom";
import NavContext from "../Contexts/NavContext";
import AuthContext from "../Contexts/AuthContext";
import MainAPI from "../APIs/MainAPI";
import { props } from "../APIs/api";
import Utils from "../Models/Utils";
import Empty from "../Components/Empty";
import StateContext from "../Contexts/StateContext";
import AlertContext from "../Contexts/AlertContext";
import AlertTypes from "../Enums/AlertTypes";

function Home() {

    const { loggedUser, cookies, isLoggedIn } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);
    const {Services, Blogs} = useContext(StateContext);
    const { openAlert } = useContext(AlertContext);

    const navigate = useNavigate();
    const [services, setServices] = useState<any[]>([]);
    const [blogs, setBlogs] = useState<any[]>([]);

    useEffect(() => {
        loadData();
    }, [Services]);

    const loadData = async () => {
        // let service_response = await MainAPI.getAllFree("service", 1, 10, {});
        // let blog_response = await MainAPI.getAllFree("blog", 1, 10, {});
        // setBlogs(blog_response.Items);
        setBlogs(Blogs.slice(0, (Blogs.length > 10 ? 10 : Blogs.length)));
        setServices(Services.slice(0, (Services.length > 10 ? 10 : Services.length)));
        // openAlert(AlertTypes.Error, `payment object ${window.consumerapp}`);
    }

    return (
        <div className="w-100 text-white px-3 pt-3" style={{position: "relative"}}>
            <div className="row w-100 align-items-center mb-3 mt-0" style={{position: "relative"}}>
                <div className="col-sm-12 col-md-6 d-flex align-items-center mb-3">
                    <NuwaAvatar userName={(isLoggedIn && loggedUser) ? loggedUser.FullName : "Guest User"} />
                    <div className="col ms-2">
                        <div>Welcom</div>
                        <div className="card-title"><b>{(isLoggedIn && loggedUser) ? loggedUser.FullName : "Guest User"}</b></div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6" style={{position: "relative"}} onClick={() => {
                    navigate("/service_search");
                    (selectNav && selectNav("order"))
                }}>
                    <SearchForm onSubmit={(word: string) => {}} />
                </div>
            </div>

            <hr className="nuwa-line-hr mt-0" />

            <div className="row align-items-center" style={{background: ""}}>
                <div className="col-sm-12 col-md-6 mb-3">
                    <div className="d-flex justify-content-between">
                        <span className="card-subtitle">Our Services</span>
                        <span className="card-subtitle" style={{cursor: "pointer"}} onClick={() => {
                            navigate("/order");
                            (selectNav && selectNav("order"))
                        }}>
                            See all
                        </span>
                    </div>
                    <div className="w-100" style={{overflow: "auto"}}>
                        <div className="d-flex py-2" style={{width: "max-content"}}>

                            {
                                services.map(srv => (
                                    <div className="nuwa-service-big-card p-2 mx-auto me-3" onClick={() => {navigate(`/service_detail/${srv.id}`);  (selectNav && selectNav("order"))}}>
                                        <div className="d-flex">
                                            <img src={`${props.baseURL}file/${srv.picture}`} className="mx-auto" style={{width: "50px"}} alt="service icon" />
                                        </div>
                                        <h6 className="card-title text-center" style={{fontSize: "14px", textWrap: "wrap", wordBreak: 'break-word'}}>{srv.title}</h6>
                                        {/* <div key={srv.id} className="nuwa-service-small-card p-3 me-3" onClick={() => {navigate(`/service_detail/${srv.id}`);  (selectNav && selectNav("order"))}}>
                                            <img src={`${props.baseURL}file/${srv.picture}`} className="mx-auto" style={{width: "40px", height: "40px"}} alt="service icon" />
                                            <h6 className="card-title text-center" style={{fontSize: "14px", textWrap: "wrap", wordBreak: 'break-word'}}>{srv.title}</h6>
                                        </div> */}
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 mb-3">
                    <div className="nuwa-card d-flex justify-content-center" 
                        style={{borderRadius: "20px", cursor: "pointer", padding: "13px"}}
                        onClick={() => {
                            navigate("/track_order");
                            (selectNav && selectNav("order"))
                        }}
                    >
                        <LocationSearchingIcon className="nuwa-icon" />
                        <span className="ms-3">Track Your Repair</span>
                    </div>
                </div>

            </div>
            <hr className="nuwa-line-hr mb-2" />

            <div className="w-100">
                <div className="d-flex justify-content-between">
                    <span className="card-subtitle">Nuwa Blog</span>
                    <span className="card-subtitle" style={{cursor: "pointer"}} onClick={() => {
                            navigate("/blogs");
                            (selectNav && selectNav("blogs"))
                        }}>See all</span>
                </div>
                <div className="w-100" style={{overflow: "auto"}}>
                    <div className="d-flex py-3" style={{width: "max-content"}}>

                        {
                            blogs.length > 0 ?
                            blogs.map(blg => (
                                <div key={blg.id} className="nuwa-blog-container me-3" onClick={() => {navigate(`/blog_detail/${blg.id}`); selectNav && selectNav("blog")}}>
                                    <div className="w-100 mb-3" style={{height: "10rem", overflow: "hidden"}} >
                                        <img src={`${props.baseURL}file/${blg.image}`} alt="blog" className="w-100" style={{borderRadius: "20px"}} />
                                    </div>
                                    <h5 className="card-title" style={{fontSize: "15px"}}>{blg.title}</h5>
                                    <p className="card-subtitle w-100" style={{right: "0", fontSize: "12px"}}>{Utils.convertISOToDate(blg.date)}</p>
                                </div>
                            )) : (<Empty text="No Blog Found" />)
                        }

                    </div>
                </div>
            </div>

            <hr className="nuwa-line-hr mt-0" />

            <div className="w-100 py-2">
                <h5 className="card-title mb-2">Who Are We</h5>
                <p style={{fontSize: "15px", margin: 0}}>
                    NUWA Mobile Phone Repair Clinic is a customer-focused business located in Bole, Addis Ababa. We specialize in providing high-quality, 
                    efficient, and affordable repairs for smartphones, tablets, and other mobile devices. Our team of certified technicians utilizes top-tier 
                    parts and offers warranties on repairs, ensuring customer satisfaction. This proposal outlines our business model and growth strategy, 
                    seeking collaboration to further establish and expand NUWA's presence in Bole.
                    <span style={{textDecorationLine: "underline", cursor: "pointer"}} onClick={() => {
                        navigate("/about_us");
                        (selectNav && selectNav("about_us"))
                    }}>Read More</span>
                </p>
            </div>

            <hr className="nuwa-line-hr mt-0" />
        </div>
    );
}

export default Home;